import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { PrimaryIcon, SEO } from '../components';
import Layout from '../components/Layout/Layout';
import { rem, theme } from '../theme';

const AboutSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  padding: 48px 32px;

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }

  ${PrimaryIcon} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  font-size: ${rem(2)};
  text-align: justify;
  padding-top: 10px;
  padding-bottom: 10px;
  & > a {
    color: ${theme.colors.topbar.link_active_button} !important;
    text-decoration: none !important;
    font-size: ${rem(3)};
    display: contents;
  }
  & > b {
    display: contents;
  }
  margin: 0px;
`;

const BlackBox = styled.div`
  background-color: black;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: ${rem(3)};
  line-height: 20px;
  padding: 48px 48px;

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }

  & > div {
    width: 100%;
    text-align: center;
  }
`;

const BigText = styled.div`
  font-size: ${rem(8)};
  font-weight: 700;
  margin-top: 18px;
`;

type Tag = {
  name: string;
  value: number;
};

type Person = {
  firstName: string;
  lastName: string;
  team: string;
  experience: number;
  tags: Tag[];
};

type QueryResult = {
  allTeamJson: {
    nodes: Person[];
  };
  teamMetaJson: {
    popularTags: string[];
    hardwareTags: string[];
  };
};

function flatMap<T, U>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => U[]
): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

const AboutPage = () => {
  const data = useStaticQuery<QueryResult>(graphql`
    {
      allTeamJson {
        nodes {
          tags {
            name
            value
          }
        }
      }
    }
  `);

  const flat = flatMap(data.allTeamJson.nodes, it => it.tags);
  const options = [...new Set(flat.map(it => it.name))];

  return (
    <Layout>
      <SEO title="About" />
      <AboutSection>
        <TextColumn>
          B4 Limits is a vertically integrated firmware, hardware and software
          design & development company possessing <b>250 years</b> of cumulative
          design and development experience. Our mission is to create, through
          value added services, custom made, rational solutions, support our
          partners within larger projects and to optimize existing code both in
          high and low level programming languages. Our team experience also
          encompasses <b>{options.length} unique skills and competencies</b>.
        </TextColumn>
        <TextColumn>
          Our staff, consisting of high-level engineers,
          <b> 6 of whom have PhD degrees</b> in Information Technology, is based
          in Bialystok and is closely aligned with the{' '}
          <a href="https://pb.edu.pl/en/">Białystok University of Technology</a>{' '}
          (one of the best universities in Poland), ensuring access to
          promising, enthusiastic and competent engineering graduates.
        </TextColumn>
        <TextColumn>
          B4 Limits is guided by values of equality, responsibility and
          tolerance as well as equal pay principals for all, which creates a
          strong team oriented environment and foundation for positive work
          space. To this end B4 Limits has joined on as a signatory of{' '}
          <a href="http://odpowiedzialnybiznes.pl/karta-roznorodnosci/sygnatariusze-karty/">
            Responsible Business Forum in Poland
          </a>
          .
        </TextColumn>
      </AboutSection>
      <BlackBox>
        <div>
          We are passionate and dedicated in the fulfillment of our common IT
          goals – from prototype or commercialisation to a supporting role in
          the form of executing any project as part of your team!
        </div>
        <BigText>We are IT!</BigText>
      </BlackBox>
    </Layout>
  );
};

export default AboutPage;
